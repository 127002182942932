<template>
  <v-app>
    <v-app-bar
        class="header"
        app
        flat
    >
      <v-container>
        <v-row>
          <v-col class="d-flex">
            <v-img
                @click="$router.push('/')"
                alt="Vuetify Logo"
                class="mr-2 logo"
                contain
                :src="require('./assets/images/logo.png')"
                height="34"
                position="center left"
            />
            <v-spacer></v-spacer>

            <!--
            <v-btn depressed>
              <v-icon>far fa-envelope</v-icon>
            </v-btn>
            -->
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <template>
        <router-view/>
      </template>
    </v-main>

    <!--
    <v-row>
      <v-col class="footer pa-10">
        Wishgrid
      </v-col>
    </v-row>
    -->

  </v-app>

</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
