<template>
  <v-container class="collection">

    <v-row
        v-if="wishgrid"
        class="text-center">
      <v-col
          cols="12">

        <v-img
          :src="wishgrid.imageUrl"
          class="collection-cover"
          cover
          height="200"
          width="200"
        />
        <span class="title">{{ wishgrid.title }}</span>
      </v-col>

      <v-col
          :cols="12"
          class="display-selection">
        <v-spacer></v-spacer>
        <v-btn
            @click="displayGrid = !displayGrid"
            :class="{ 'active' : displayGrid }"
            class="mr-5"
            text
            depressed>
          <v-icon>fas fa-th </v-icon>
        </v-btn>
        <v-btn
            @click="displayGrid = !displayGrid"
            :class="{ 'active' : !displayGrid }"
            text
            depressed>
          <v-icon>fas fa-list</v-icon>
        </v-btn>
      </v-col>

      <template v-if="items.length > 0">

        <template
            v-for="(item, index) in items">

          <ItemGrid
              v-if="displayGrid"
              :item="item"
              :key="index"
              @purchase="purchaseItem(item.id)">
          </ItemGrid>

          <ItemList
              v-if="!displayGrid"
              :item="item"
              :key="index"
              @purchase="purchaseItem(item.id)">
          </ItemList>

        </template>
      </template>

    </v-row>

    <v-row v-if="notFound">
      <v-col class="py-20">
        Nothing found! at all!
        <div class="d-flex id-input-wrapper">
          <v-text-field
              v-model="wishId"
              placeholder="ABC123"
              :maxlength="6"
              class="id-input"
              hide-details
              outlined
              dense
          ></v-text-field>
          <v-btn
              :disabled="wishId.length !== 6"
              @click="getWishlist"
              class="cta"
              depressed>
            Visa samling
          </v-btn>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<style lang="scss">
  @import "../assets/style.scss";
</style>

<script>

import { db } from '@/main'
import { doc, getDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import ItemGrid from "@/components/ItemGrid";
import ItemList from "@/components/ItemList";

 export default {
    name: 'Collection',
   components: {ItemList, ItemGrid},
   data: () => ({
      wishId: '',
      displayGrid: true,
      wishgrid: null,
      items: [],
      notFound: false
    }),

   methods: {

     collectionNotFound() {
       this.notFound = true
       this.wishId = ''
     },

     async getWishlist() {

       const docRef = doc(db, "wishlists", this.wishId);
       const docSnap = await getDoc(docRef);

       if (docSnap.exists()) {
         this.notFound = false
         console.log("Document data:", docSnap.data());
         this.wishgrid = docSnap.data();
         await this.getItems()
       } else {
         this.collectionNotFound()
       }
     },

     async getItems() {
       const docsSnap = await getDocs(collection(db,"wishlists/" + this.wishId + "/items"));

       let itemList = []
       docsSnap.forEach((doc) => {
         // doc.data() is never undefined for query doc snapshots
         console.log(doc.id, " => ", doc.data());
         itemList.push(doc.data())
       });
       this.items = itemList
     },

     async purchaseItem(itemId) {

       const itemRef = doc(db, "wishlists/" + this.wishId + "/items", itemId);

       await updateDoc(itemRef, {
         isPurchased: true
       });
     }
   },

   mounted() {

      if (this.$route.params.collection1 !== null) {
        this.wishgrid = this.$route.params.collection1
      }

      if (this.wishgrid) {
        this.wishId = this.wishgrid.id
        this.getItems()
      } else {

        // Check params
        if (this.$route.params.id && this.$route.params.id.length === 6) {
          this.wishId = this.$route.params.id
          this.getWishlist()
        } else {
          this.collectionNotFound()
        }
      }
   },

  }
</script>
