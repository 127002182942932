<template>

  <div>

    <!--

    <v-container class="container-wrapped">

      <v-row
          class="jumbo text-left">
        <v-col
            :lg="7"
            :md="12"
            class="col-center">
          <h1 class="jumbo-title">
            <span class="d-block">Dina önskningar</span>
            <span class="color-black">på ett ställe</span>
          </h1>
          <span class="jumbo-byline">Sell with expertise, storytelling and skyrocketing engagement on your e-commerce with up to 36% add-to-cart.</span>
          <div class="d-flex id-input-wrapper">
            <v-text-field
                v-model="wishId"
                placeholder="ABC123"
                :maxlength="6"
                color="black"
                class="id-input"
                hide-details
                outlined
                dense
            ></v-text-field>
            <v-btn
                :disabled="wishId.length !== 6"
                @click="getWishlist"
                class="cta"
                depressed>
              Visa samling
            </v-btn>
          </div>
          <div class="input-byline">
          <span
              class="no-results"
              v-if="resultText.length > 0">
            {{ resultText }}
          </span>
            <span v-else>
            Ange samlingens id
          </span>
          </div>
        </v-col>
        <v-col
            :cols="5"
            class="hidden-md-and-down">
          <v-img
              alt="Shopping"
              class="jumbo-image"
              contain
              :src="require('../assets/images/shopping.png')"
              transition="scale-transition"
          />
        </v-col>

        <v-col class="hidden-md-and-down">
          <div class="separator-grey"></div>
        </v-col>
      </v-row>

      <v-row class="usp">

        <v-col
            :md="6"
            :sm="12"
            class="col-center">
          <div>
            <span class="pre-title">Följ samlingar</span>
            <h2 class="title">Var du än är</h2>
            <p>This is a great way to mirror your physical-space-experience, digitally. Use it to market a shopping center, a store/department store or even a hotel in new ways. Share your story, talk about news in the area.</p>
          </div>
        </v-col>

        <v-col
            :md="6"
            :sm="12">
          <v-img
              alt="Shopping"
              class="jumbo-image"
              contain
              :src="require('../assets/images/usp_1.jpeg')"
              transition="scale-transition"
          />
        </v-col>
      </v-row>

      <v-row class="usp">

        <v-col
            :md="6"
            :sm="12">
          <v-img
              alt="Shopping"
              class="jumbo-image"
              contain
              :src="require('../assets/images/usp_1.jpeg')"
              transition="scale-transition"
          />
        </v-col>

        <v-col
            :md="6"
            :sm="12"
            class="col-center">
          <div class="pl-10">
            <span class="pre-title">Följ samlingar</span>
            <h2 class="title">Var du än är</h2>
            <p>This is a great way to mirror your physical-space-experience, digitally. Use it to market a shopping center, a store/department store or even a hotel in new ways. Share your story, talk about news in the area.</p>
          </div>
        </v-col>

      </v-row>

    </v-container>

    <section
      :style="{ backgroundImage: 'url(' + require('@/assets/images/bg.jpg') + ')' }"
      class="usp-jumbo">
      <v-container class="container-wrapped">
        <v-row class="fill-height">
          <v-col class="col-center fill-height">
            <h2>
              Live Shopping
            </h2>
            <p>
              Stream shoppable live video. It's entertaining and amazing for boosting sales.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    -->

  </div>

</template>

<script>

import { db } from '@/main'
import { doc, getDoc } from "firebase/firestore";

export default {
  name: 'Home',

  data: () => ({
    wishId: '',
    resultText: '',
    wishgrid: null,
  }),

  methods: {

    async getWishlist() {

      const docRef = doc(db, "wishlists", this.wishId);
      console.log("docRef",docRef)
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        this.wishgrid = docSnap.data();
        this.resultText = "Got it brother!"

        this.$router.push({
          name: "collection",
          params: {  collection1: this.wishgrid, id: this.wishgrid.id },
        });
      } else {
        this.resultText = "Hey! Sorry to say but we couldn't find any collection with that ID. Please try again!"
      }
    }

  },

}
</script>
