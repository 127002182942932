<template>
  <v-col
      :cols="12"
      class="py-1">
    <div
        class="item-list d-flex"
        :class="{ 'is-purchased' : item.isPurchased }">

      <div class="image-wrapper">
        <v-img
            v-if="item.imageUrl"
            :src="item.imageUrl"
            cover
            height="90"
            width="90"
        />
      </div>

      <div>
        <div class="item-info">
          <span>{{ item.title }}</span>
          <div class="price">
            {{ item.price }}
          </div>
        </div>
      </div>

      <v-spacer></v-spacer>

      <div class="item-footer">
        <div
            v-if="item.isPurchased"
            class="purchased">
          Köpt!
        </div>
        <template v-else>
          <v-btn
              @click="$emit('purchase')"
              :class="{ 'full-width' : !item.url }"
              depressed>
            Köp!
          </v-btn>
          <v-btn
              v-if="item.url"
              :href="item.url"
              target="_blank"
              depressed>
            Till produkten
          </v-btn>
        </template>
      </div>

    </div>
  </v-col>
</template>

<script>
export default {
  name: "ItemList",
  props: {
    item: Object,
  }
}
</script>

<style scoped>

</style>