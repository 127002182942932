import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/compat/app';
import VueRouter from 'vue-router'
import { firestorePlugin } from 'vuefire'

import 'firebase/compat/firestore';

Vue.use(firestorePlugin)
Vue.use(VueRouter)

import Home from './components/Home';
import Collection from "@/components/Collection";

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/collection/:id',
      name: 'collection',
      component: Collection,
    },
    {
      path: '/collection/*',
      name: 'collection',
      component: Collection,
    },
    {
      path: '/collection',
      name: 'collection',
      component: Collection,
    }
  ]
});

Vue.config.productionTip = false

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1Lx_tm1l1-MUKypnSiwcrqc1ikkCKLc8",
  authDomain: "wishbucketapp.firebaseapp.com",
  projectId: "wishbucketapp",
  storageBucket: "wishbucketapp.appspot.com",
  messagingSenderId: "586184523839",
  appId: "1:586184523839:web:3f8f6a84a79ab06116788a",
  measurementId: "G-KXKKEB07XF"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore()
Vue.use(db)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
